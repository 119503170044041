#confirm-page {
    background-image: url('./assets/images/water-drops2.jpg');
    background-repeat: no-repeat;
    height: 800px; 
    min-width: 100%;
    background-position: center;
    background-size: cover;
}

#confirm {
    display: flex;
    justify-content: center;
}

.confirmation-text {
    font-size: 30px;
    color: white;
    font-family: 'Kanit', sans-serif;
    letter-spacing: 1px;
    margin-top: 250px; 
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    margin-right: 300px; 
    margin-left: 300px; 
    border-radius: 5px;
    /* text-decoration: underline rgba(253, 145, 22, 0.85); */
}


#confirm a {
    /* text-align: center; */
    color: #CAE2E2; 
    font-size: 20px;
    font-weight: bold; 
    font-family: 'Kanit', sans-serif;
    letter-spacing: 1.5px;
}



@media (max-width: 767px) {
    .confirmation-text {
        font-size: 20px;
    }

    #confirm a {
        font-size: 15px;
    }
}