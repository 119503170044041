#footer {
    background-color: #2A5151 ;
}

#footer h1 {
    font-family: 'Wallpoet', sans-serif;
    color: #CAE2E2;
    margin-left: 15%;
    font-size: 25px;
    margin-top: 10%
}

.footer-warning {
    margin-left: 15%;
    width: 80%
}

#footer p {
    color: white; 
    font-family: 'Kanit', sans-serif;
}

#footer p a {
    text-decoration: none;
    /* text-decoration: underline #D79116; */
    color: #5DACAC; 
    /* text-underline-offset: 3px; */
}

.footer-contact {
    color: white !important;
    padding-top: -15px !important
}

#footer ul {
    color: white; 
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    text-align: end;
    margin-top: 20%; 
    margin-right: 10%
}

#footer ul li a {
    text-decoration: none;
    color: white; 
    transition-duration: .5s;
}

#footer ul li a:hover {
    color: #5DACAC
}

#footer ul li {
    list-style-type: none; 
    text-decoration: underline white;
    text-underline-offset: 3px;
}

.locations {
    height: 350px;
    overflow: hidden;
}

.locations img {
    width: 100%;
}

@media (max-width: 1256.98px) {
    .footer-warning {
        width: 100%;
    }

    .locations {
        height: auto;
        overflow: hidden;
    }

    #footer h1 {
        font-size: 25px;
    }

}

@media (max-width: 1110px) {
    #footer p {
        font-size: 12px;
    }
}

@media (max-width: 991.98px) {
    #footer p {
        font-size: 10px;
    }

    #footer ul {
        font-size: 15px
    }

    #footer h1 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .locations {
        display: none;
    }

    .footer-warning {
        width: 50%;
        margin-left: 5%;
    }

    #footer h1 {
        margin-left: 5%; 
        margin-top: 5%;
    }

    #footer ul {
        text-align: end;
        margin-top: -15%;
        margin-right: 5%;
    }

    #footer p { 
        font-size: 12px;
        /* margin-bottom: 10% */
    }

    

}

@media (max-width: 550px) {
    .footer-warning {
        width: 60%
    }

    #footer ul {
        margin-top: -20%;
        margin-right: 5%;
    }
}

@media (max-width: 485px) {

    #footer p {
        font-size: 10px;
    } 

    #footer ul {
        margin-top: -25%;
    }
}

@media (max-width: 375.98px) {
    #footer ul {
        margin-top: -30%;
    }
}
